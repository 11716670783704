import styled from 'styled-components'
import { redLighten } from '../styles/variables'

const Heading = styled.h3`
font-size: 1.2em;
${props => props.align && `text-align: ${props.align};`}
${props => props.underline && `text-decoration: underline;`}
color: ${redLighten};
margin: 0;
`

export default Heading