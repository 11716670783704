import React from 'react'
import Title from '../../components/Title'
import Text from '../../components/Text'
import Link from '../../components/Link'
import RoundImage from '../../components/RoundImage'
import './style.scss'

const About = () => {
    return (
        <div className='about'>
            <RoundImage src='/images/profile_ich.jpg' />
            <div className='content'>
                <Title>
                    Moin, I'm Martin <span aria-label='wave' role='img'>👋</span>
                </Title>
                <Text>
                    I'm a JavaScript developer and Python enthusiast from Germany <span aria-label='germany' role='img'>🇩🇪</span><br />
                    I particularly enjoy to develop advanced and stable web applications and overcome new challenges.
            </Text>
                <Text>
                    If I'm not coding, I'll probably explore the world <span aria-label='globus' role='img'>🌎</span>and take some <Link href='https://www.instagram.com/the.small.thingys/' target='_blank' rel='nofollow'>pictures!</Link>
            </Text>
            </div>
        </div>
    )
}

export default About