import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import './style.scss'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import Link from '../../components/Link'

const Project = ({ project }) => {

    const {
        title,
        text,
        github,
        web
    } = project

    return (
        <Card className='card'>
            <CardContent>
            <Heading><Link href={web || github} target='_blank'>{title}</Link></Heading>
                <Text small>{text}</Text>
            </CardContent>
            <CardActions className='actions'>
                {github && <Link href={github} target='_blank'><i className="fab fa-github"></i></Link>}
                {web && <Link href={web} target='_blank'><i className="fas fa-laptop-code"></i></Link>}
            </CardActions>
        </Card>
    )
}

export default Project