import styled from 'styled-components'
import { redLighten } from '../styles/variables'

const Title = styled.h2`
font-size: 1.5em;
${props => props.align && `text-align: ${props.align};`}
${props => props.large && `font-size: 2em;`}
${props => props.small && `font-size: 1.2em;`}
${props => props.underline && `text-decoration: underline;`}
color: ${redLighten};
`

export default Title