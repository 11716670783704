import React, { Component } from 'react'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Link from '../../components/Link'
import { Link as ScrollLink, animateScroll as scroll, } from 'react-scroll'
import { isMobile } from '../../lib/utils'
import './style.scss'

class Nav extends Component {
    state = {
        open: false,
        shadow: false
    }

    componentDidMount() {
        window.addEventListener('scroll', (e) => {
            if (window.scrollY >= 25 && !this.state.shadow) {
                this.setState({ shadow: true })
            }
            if (window.scrollY < 25 && this.state.shadow) {
                this.setState({ shadow: false })
            }
        })
    }

    toggleDrawer = () => {
        this.setState({ open: !this.state.open })
    }

    handleTop = e => {
        e.preventDefault()
        scroll.scrollToTop()
        this.toggleDrawer()
    }

    links = [
        <Link onClick={this.handleTop} href='/'><code>&lt; martingassner &#47;></code></Link>,
        <ScrollLink href='#projects' onClick={this.toggleDrawer} to='projects' smooth={true} duration={500}>projects</ScrollLink>,
        <Link onClick={this.toggleDrawer} href='https://www.barefootintotheworld.com' target='_blank'>blog <i className="fas fa-external-link-alt"></i></Link>
    ]

    sideList = () => (
        <List className='list'>
            {this.links.map((link, i) => {
                if (i === 0) {
                    return (
                        <>
                            <ListItem>{link}</ListItem>
                            <Divider />
                        </>
                    )
                }

                return <ListItem>{link}</ListItem>
            })}
        </List>
    )

    render() {
        return (
            <>
                {
                    isMobile
                        ? <div className='nav mobile' >
                            <span className='icon' onClick={this.toggleDrawer}>
                                <i className="fas fa-bars"></i>
                            </span>
                            <Drawer open={this.state.open} onClose={this.toggleDrawer}>
                                {this.sideList()}
                            </Drawer>
                        </div>
                        : <nav className={`nav desktop${this.state.shadow ? ' shadow' : ''}`}>
                            {this.links.map(link => link)}
                        </nav>
                }
            </>
        )
    }
}

export default Nav
