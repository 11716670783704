import React from 'react';
import About from './sections/About'
import Projects from './sections/Projects'
import Nav from './sections/Nav'
import Footer from './sections/Footer'
import Container from '@material-ui/core/Container'

const App = () => {
  return (
    <>
      <Nav />
      <Container maxWidth={'md'}>
        <About />
        <Projects />
      </Container>
      <Footer />
    </>
  )
}

export default App;
