import styled from 'styled-components'
import { redLighten, red } from '../styles/variables'

const Link = styled.a`
color: ${redLighten};
text-decoration: none;
${props => props.spacing && 'margin: 5px 10px'}
transition: color 0.3s ease;

&:hover {
    color: ${red};
}

i {
    margin-right: 5px;
}
`

export default Link