import React from 'react'
import './style.scss'
import Title from '../../components/Title'
import Project from '../../container/Project'
import projects from '../../projects.json'

const About = () => {
    return (
        <div name='projects' className='projects'>
            <Title align='center' large underline>Recent Projects</Title>
            <div className='cards'>
                {
                    projects.map(project => <Project project={project} />)
                }
            </div>
        </div>
    )
}

export default About