import React from 'react'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import Link from '../../components/Link'
import './style.scss'

const Footer = () => {
    return (
        <>
            <Divider />
            <Container maxWidth={'md'}>
                <div className='Footer'>
                    <div className='text'>
                        <Heading>Greetings</Heading>
                        <Text>
                            Thank you for visiting my website <span aria-label='thanks' role='img'>🙌</span><br />
                            I hope you like my projects and get in touch with me. I would really appreciate it!
                        </Text>
                    </div>
                    <div className='follow'>
                        <Heading>Find me on other platforms</Heading>
                        <ul>
                            <li><Link href='https://github.com/MartinGassner' target='_blank' rel='nofollow'><i className="fab fa-github"></i> View source on Github</Link></li>
                            <li><Link href='https://twitter.com/mar7inius' target='_blank' rel='nofollow'><i className="fab fa-twitter"></i> Follow me on Twitter</Link></li>
                            <li><Link href='https://www.instagram.com/the.small.thingys/' target='_blank' rel='nofollow'><i className="fab fa-instagram"></i> See my pictures on Instagram</Link></li>
                            <li><Link href='https://www.xing.com/profile/Martin_Gassner18' target='_blank' rel='nofollow'><i className="fab fa-xing"></i> Find me on Xing</Link></li>
                        </ul>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Footer